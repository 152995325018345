* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.main-container {
  display: flex;
}
main {
  width: 100%;
  padding: 20px;
}
.side-bar {
  background: #151718;
  color: #fff;
  height: 100vh;
  width: 200px;
  transition: width 0.3s;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.sidebar-link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.3s;
}
.sidebar-link:hover {
  background: #135899;
  color: #000;
  transition: all 0.3s;
}
.active {
  background: #135899;
  color: #fff;
  text-decoration: none;
}
.icon,
.link_text {
  font-size: 20px;
}

.border-r {
  border-right: hsl(198 6.6% 15.8% / 0.5) 2px solid;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #a9a9a9;
}
