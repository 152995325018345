.center-el {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translateX(-50%);
}

.file-input {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.input-file {
  position: absolute;
  font-size: 999px;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
