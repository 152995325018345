.bg {
  background-color: #151718;
  color: white;
}

.border-l {
  border-left: hsl(198 6.6% 15.8% / 0.5) 2px solid;
}

.bg-chat {
  background-color: #26292b;
  color: white;
}
